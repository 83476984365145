// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import Img from 'gatsby-image'

// import GoogleMapWithMarker from '@bodhi-project/components/lib/map/GoogleMapWithMarker'

// import Division from '@bodhi-project/components/lib/Division'
// import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
// import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
// import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'K12 School Curriculum | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    'Learning Wings Education Systems has K1-12 and Early Education schools spread over North India. At our schools the goal is to nurture the dreams of tender hearts. Every child is equipped with the confidence to pursue the dreams and realize them. In this world of turmoil and unrest they learn to pave their own path to success.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <h1 class="shadow" data-shadow="LWES Curriculum">
      LWES Curriculum
    </h1>
    <div style={{ maxWidth: '50rem' }}>
      <p>
        The Cambridge International School Curriculum has been designed keeping
        in mind the pedagogic needs of the regions our schools are based in and
        connecting it to international standards. Our curriculum has been
        developed by carrying out ongoing small-scale research projects, pilot
        projects and action research.
      </p>
      <p>
        We have tried to create a flexible curricular model that can be
        personalized to the needs of varied learners. Our curriculum not only
        covers academic areas but also covers the social, cultural issues and
        challenges that the 21st century poses for young people. Our students
        are free to choose from a range of curriculums.
      </p>
      <h2>Primary School Education</h2>
      <p>
        <strong>Age Group — 6-11 Years</strong>
      </p>
      <p>
        Cambridge offers a curriculum which is balanced and broadly based for
        the young students to start the journey of education without burden or
        fear. Nurturing environment of opportunities helps in academic and
        co-scholastic develop curiosity unlimited, which ensures that students
        have fun, play and aim to achieve high.
      </p>
      <p>
        Primary education consists of an year cycle: first to fifth classes. The
        primary curriculum aims to provide a broad learning experience and
        encourages a rich variety of approaches to teaching and learning that
        cater to the varied needs of individual children. The primary curriculum
        is designed to nurture the child in all dimensions of his or her
        life—spiritual, moral, cognitive, emotional, imaginative, aesthetic,
        social and physical.
      </p>
      <h2>Middle School Programme</h2>
      <p>
        <strong>The Middle School</strong>
      </p>
      <p>
        During the middle school, the student’s intellectual, aesthetic,
        physical and cultural growth is intensified and extended. It is at this
        stage school parents play pivotal role in shaping students as confident
        and resilient individuals.
      </p>
      <p>
        Interdisciplinary methods of teaching help to explore beyond limited
        learning, peer interactions and presentations help each student to be
        socially aware and active.
      </p>
      <p>
        Setting a high standard facilitates the smooth transition from childhood
        to adolescence. Students are given confidence that they can succeed.
        They are made active partners by building respectful teacher-learner
        relationships that take learners’ viewpoint and experiences into
        account. Pastoral care is given equal value for creating the feeling of
        belonging for students to be independent thinkers.
      </p>
      <p>
        Using assessment for learning to help learners assess their work,
        reflect on how they learn, and become active members of the process.
      </p>
      <p>
        Clubs and Activities are carefully planned to balance the physical and
        mental growth.
      </p>
      <h2>The Secondary School</h2>
      <p>
        <strong>Stepping Into Life</strong>
      </p>
      <p>
        Knowledge and skills gained during the Middle School years equip
        students with a firm foundation in the core subjects and develop strong
        learning skills to pursue a variety of educational streams in future.
      </p>
      <p>
        Opportunities are provided to research, solve problems and make
        considered decisions so that over a period our students achieve
        self-reliance in learning and develop confidence and an understanding of
        the learning process.
      </p>
      <p>
        Leadership is nurtured through various opportunities to lead, organise
        events for academic and social areas. At this stage, we believe, each
        student is to be nurtured to be brave, intelligent and resilient.
      </p>
      <h2>The Senior Seconday School</h2>
      <p>
        <strong>Gateway to a creative life</strong>
      </p>
      <p>
        Study in this phase becomes more specialised and students become
        focussed on future career targets. Specialist teaching is emphasised
        with the options of choices from Humanities, Commerce, and Sciences. But
        these do not limit our students from dreaming, they are motivated to
        take up their passion.
      </p>
      <p>
        Our school is well resourced with excellent caring staff, facilities,
        equipment and technology for their future to be as per their dreams.
      </p>
      <p>
        Our commitment for future prepared students is not only an objective but
        a commitment to not let these valuable years be lost. Along with core
        subjects of sciences, commerce and humanities, we provide Liberal Arts
        possibilities with subjects like Psychology, Cosmetology, Food
        Technology, Fashion Technology, Information Technology, Legal studies.
      </p>
      <p>
        Special care is taken to offer subjects that are contemporary and
        relevant for the future career. Diverse subject combinations are offered
        to make this crucial phase of schooling joyous and stress free.
      </p>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
